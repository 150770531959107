.Bar {
    width: 100%;
    height: 100%;
    font-family: 'Titillium Web', sans-serif;
}

.TitleWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.Title {
    font-weight: bold;
}

.Precent {
    color: rgb(8, 253, 216);
}

.Status {
    width: 100%;
    height: 7px;
    background: #8d8d8d;
    border-radius: 7px;
    position: relative;
}

.Progress {
    width: 80%;
    height: 7px;
    background: #08fdd8;
    border-radius: 7px;
    position: relative;
    transform: translateY(-7px);
}