.Card {
    padding: 1rem;
    background: rgb(46, 46, 46);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
    /* border-radius: 1rem; */
    width: 100%;
}

.Card i:first-of-type {
    color: rgb(8, 253, 216);
    font-size: 1.5rem;
}

.Card h2 {
    margin-top: 0.6rem;
    margin-bottom: 1rem;
    font-family: swp;
    font-size: 2.3rem;
}