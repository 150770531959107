.Education {
    padding: 2rem 5rem;
    width: 100%;
    height: auto;
    margin: auto;
    font-family: 'Titillium Web', sans-serif;
}

@media (max-width: 1400px) {
    .Education {
        padding: 2rem 0;
    }
}

.Education h4 {
    margin: 0;
}

.Education h5 {
    margin: 0;
    margin-top: 0.2rem;
}

.Title {
    text-align: center;
    text-align: left;
    font-size: 0.7rem;
    color: #8d8d8d;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 200;
    margin: 0;
    margin-bottom: 1rem;
}

.Education p {
    margin: 0;
    font-family: 'Titillium Web', sans-serif;
    /* font-style: italic; */
    font-weight: 200;
}

.Wrapper {
    display: flex;
    justify-content: space-evenly;
    /* margin: 3rem 0 */
}

.Wrapper span, .Wrapper h5 {
    color: rgb(8, 253, 216);
}

.Wrapper span {
    cursor: pointer;
}

.Edu {
    min-width: 48%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.Exp {
    min-width: 48%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.Edu i:first-of-type, .Exp i:first-of-type {
    color: rgb(8, 253, 216);
    font-size: 1.5rem;
}

.Edu h2, .Exp h2 {
    margin-top: 0.6rem;
    margin-bottom: 1rem;
    font-family: swp;
    font-size: 2.3rem;
}

.ListWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

@media (max-width: 1150px) {
    .ListWrapper {
        flex-direction: column;
    }
}

.RespTech {
    margin-top: 1rem;
    width: 48%;
}

.RespTech p {
    font-weight: 400;
    font-style: normal;
}

.RespTech ul {
    font-size: 1rem;
    /* color: #8d8d8d; */
    font-family: 'Titillium Web', sans-serif;
    font-weight: 200;
}

@media (max-width: 768px) {
    .Wrapper {
        flex-direction: column;
    }
    .Education{
        padding-bottom: 8rem;
    }
}