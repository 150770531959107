.scene {
  min-width: 25rem;
  max-width: 25rem;
  perspective: 600px;
  z-index: 500;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.scene a{
  text-decoration: none;
  color: inherit;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.card.is-flipped {
  transform: rotateY(-180deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100vh;
  color: white;
  text-align: center;
  /* font-weight: bold; */
  font-size: 40px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* overflow: scroll; */
}

.card__face--front {
  background: rgb(46, 46, 46);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.9rem;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding-top: 30vh;
}

.card__face--back {
  background: rgb(46, 46, 46);
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.card ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.picture {
  /* width: 21rem;
  height: 21rem;
  min-height: 21rem;
  border-radius: 21rem; */
  top: 0;
  height: 25vh;
  width: calc(25vh);
  border-radius: calc(25vh);
  margin-top: 3rem;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  /* background: url('https://scontent-vie1-1.xx.fbcdn.net/v/t1.15752-9/94318169_2532840376967533_8893859616169918464_n.png?_nc_cat=111&_nc_sid=b96e70&_nc_oc=AQniT4aPF8mhXJp__EjKq3AYouiBIgHDyG3e4wTFldpL4sP0-2KvZmACgF8KDqrgf9j-DxW9MrRpcgHix9j8_83-&_nc_ht=scontent-vie1-1.xx&oh=a25f4314230fed40344737e7d3a2cea6&oe=5EC979AB'); */
  background: url('https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fme_1.jpg?alt=media&token=095c8c16-453a-4d68-ad73-eb694f2f1559');
  background-size: cover;
  /* box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.5); */
  /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 0;
  position: absolute;
}

@media (max-height: 950px) {
  /* .picture {
    width: 15rem;
    height: 15rem;
    min-height: 15rem;
    border-radius: 15rem;
    margin-top: 3rem;
    filter: grayscale(70%);
    -webkit-filter: grayscale(100%);
    background: url('https://scontent-vie1-1.xx.fbcdn.net/v/t1.15752-9/94318169_2532840376967533_8893859616169918464_n.png?_nc_cat=111&_nc_sid=b96e70&_nc_oc=AQniT4aPF8mhXJp__EjKq3AYouiBIgHDyG3e4wTFldpL4sP0-2KvZmACgF8KDqrgf9j-DxW9MrRpcgHix9j8_83-&_nc_ht=scontent-vie1-1.xx&oh=a25f4314230fed40344737e7d3a2cea6&oe=5EC979AB');
    background-size: cover;
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
  } */
  h2 {
    font-size: 2rem;
  }
}

.contact_wrapper{
  display: flex;
  flex-direction: column;
}

.name {
  margin-top: 1rem;
  text-align: center;
  vertical-align: middle;
  font-size: 2.7rem;
}

.contact {
  width: 100%;
}

.contact ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: 1rem;
  justify-content: center;
  vertical-align: middle;
  margin: 1.7rem;
}

.contact li {
  margin: 0.3rem 0;
}

.contact ul a {
  font-size: 1rem;
  vertical-align: middle;
  color: inherit;
  text-decoration: none;
  font-weight: 400;
}

.social {
  font-size: 1.2rem;
  text-align: center;
  /* height: 100%; */
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-bottom: 10px;
}

.social i {
  margin: 0 1.5rem;
}

.title {
  margin-top: 0.1rem;
  text-align: center;
  vertical-align: middle;
  font-size: 1.1rem;
  /* color: #8d8d8d; */
  font-family: 'Titillium Web', sans-serif;
  font-weight: 200;
}

.bio {
  font-size: 1rem;
  margin-top: 0.1rem;
  font-family: 'Titillium Web', sans-serif;
  font-style: italic;
  font-weight: 200;
  text-align: left;
}

.flip {
  margin-top: 0.4rem;
  font-family: 'Rock Salt', cursive;
  font-size: 1.5rem;
  color: rgb(8, 253, 216) !important;
  /* height: 100%; */
  display: flex;
  align-items: center;
}

.flip:hover {
  color: rgb(0, 0, 0) !important;
}

.back p {
  font-size: 0.7rem;
  margin-bottom: 3rem;
}

.back input, textarea {
  background: rgb(24, 24, 24);
  padding: 0;
  margin: 0.8rem;
  height: 1.5rem;
  color: white;
  /* padding: 0 0.5rem */
}

.back textarea {
  height: 10rem;
  color: white;
  padding: 0.5rem 0.2rem;
}

@media (max-width: 768px) {
  /* .picture {
    margin-top: 1rem;
  } */
  .scene {
    width: auto;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
  }
  .card__face {
    height: 128vh;
  }
  /* .flip {
    margin-top: 0;
  }
  .cont {
    margin-bottom: 0;
  } */
  .card__face h2 {
    font-size: 24px;
  }
  .back p {
    margin-bottom: 1rem;
  }
  .back input, textarea {
    margin-left: 0;
  }
  .social {
    margin-bottom: 8rem;
  }
  .arrowContainer {
    margin-bottom: 8rem;
  }
}