.CircleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Circle {
    width: 50%;
}

@media (max-width: 768px) {
    .CircleWrapper div {
        font-size: 0.9rem;
    }
}