@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css);
@import url(https://fonts.googleapis.com/css?family=Mansalva|Roboto+Mono:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,400;1,200;1,400&display=swap);
/* @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css'); */
@font-face {
  font-family: "swp";
  src: url(/static/media/sweetpurple.57bf06b1.otf) format("opentype");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(17, 17, 17);
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  /* font-family: "Open Sans",sans-serif; */
  font-family: 'Titillium Web', sans-serif;
}
.App {
  text-align: center;
  background: rgb(17, 17, 17);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background-logo {
  position: fixed;
  width: calc(100vw - 30rem);
  height: 100%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .background-logo {
    position: fixed;
    width: 100%;
    height: calc(100vh - 10vh);
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.SideMenu_SideMenu__C0DY- {
    background: #181818;
    min-width: 5rem;
    max-width: 5rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 500;
    overflow: hidden;
}

.SideMenu_Mobile__1uyeZ {
    background: #181818;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 500;
    overflow: hidden;
}
.scene {
  min-width: 25rem;
  max-width: 25rem;
  perspective: 600px;
  z-index: 500;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
}
.scene a{
  text-decoration: none;
  color: inherit;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.card.is-flipped {
  transform: rotateY(-180deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100vh;
  color: white;
  text-align: center;
  /* font-weight: bold; */
  font-size: 40px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* overflow: scroll; */
}

.card__face--front {
  background: rgb(46, 46, 46);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.9rem;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding-top: 30vh;
}

.card__face--back {
  background: rgb(46, 46, 46);
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.card ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
}

.picture {
  /* width: 21rem;
  height: 21rem;
  min-height: 21rem;
  border-radius: 21rem; */
  top: 0;
  height: 25vh;
  width: calc(25vh);
  border-radius: calc(25vh);
  margin-top: 3rem;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  /* background: url('https://scontent-vie1-1.xx.fbcdn.net/v/t1.15752-9/94318169_2532840376967533_8893859616169918464_n.png?_nc_cat=111&_nc_sid=b96e70&_nc_oc=AQniT4aPF8mhXJp__EjKq3AYouiBIgHDyG3e4wTFldpL4sP0-2KvZmACgF8KDqrgf9j-DxW9MrRpcgHix9j8_83-&_nc_ht=scontent-vie1-1.xx&oh=a25f4314230fed40344737e7d3a2cea6&oe=5EC979AB'); */
  background: url('https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fme_1.jpg?alt=media&token=095c8c16-453a-4d68-ad73-eb694f2f1559');
  background-size: cover;
  /* box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.5); */
  /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.1);
  margin-bottom: 0;
  position: absolute;
}

@media (max-height: 950px) {
  /* .picture {
    width: 15rem;
    height: 15rem;
    min-height: 15rem;
    border-radius: 15rem;
    margin-top: 3rem;
    filter: grayscale(70%);
    -webkit-filter: grayscale(100%);
    background: url('https://scontent-vie1-1.xx.fbcdn.net/v/t1.15752-9/94318169_2532840376967533_8893859616169918464_n.png?_nc_cat=111&_nc_sid=b96e70&_nc_oc=AQniT4aPF8mhXJp__EjKq3AYouiBIgHDyG3e4wTFldpL4sP0-2KvZmACgF8KDqrgf9j-DxW9MrRpcgHix9j8_83-&_nc_ht=scontent-vie1-1.xx&oh=a25f4314230fed40344737e7d3a2cea6&oe=5EC979AB');
    background-size: cover;
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
  } */
  h2 {
    font-size: 2rem;
  }
}

.contact_wrapper{
  display: flex;
  flex-direction: column;
}

.name {
  margin-top: 1rem;
  text-align: center;
  vertical-align: middle;
  font-size: 2.7rem;
}

.contact {
  width: 100%;
}

.contact ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: 1rem;
  justify-content: center;
  vertical-align: middle;
  margin: 1.7rem;
}

.contact li {
  margin: 0.3rem 0;
}

.contact ul a {
  font-size: 1rem;
  vertical-align: middle;
  color: inherit;
  text-decoration: none;
  font-weight: 400;
}

.social {
  font-size: 1.2rem;
  text-align: center;
  /* height: 100%; */
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-bottom: 10px;
}

.social i {
  margin: 0 1.5rem;
}

.title {
  margin-top: 0.1rem;
  text-align: center;
  vertical-align: middle;
  font-size: 1.1rem;
  /* color: #8d8d8d; */
  font-family: 'Titillium Web', sans-serif;
  font-weight: 200;
}

.bio {
  font-size: 1rem;
  margin-top: 0.1rem;
  font-family: 'Titillium Web', sans-serif;
  font-style: italic;
  font-weight: 200;
  text-align: left;
}

.flip {
  margin-top: 0.4rem;
  font-family: 'Rock Salt', cursive;
  font-size: 1.5rem;
  color: rgb(8, 253, 216) !important;
  /* height: 100%; */
  display: flex;
  align-items: center;
}

.flip:hover {
  color: rgb(0, 0, 0) !important;
}

.back p {
  font-size: 0.7rem;
  margin-bottom: 3rem;
}

.back input, textarea {
  background: rgb(24, 24, 24);
  padding: 0;
  margin: 0.8rem;
  height: 1.5rem;
  color: white;
  /* padding: 0 0.5rem */
}

.back textarea {
  height: 10rem;
  color: white;
  padding: 0.5rem 0.2rem;
}

@media (max-width: 768px) {
  /* .picture {
    margin-top: 1rem;
  } */
  .scene {
    width: auto;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
  }
  .card__face {
    height: 128vh;
  }
  /* .flip {
    margin-top: 0;
  }
  .cont {
    margin-bottom: 0;
  } */
  .card__face h2 {
    font-size: 24px;
  }
  .back p {
    margin-bottom: 1rem;
  }
  .back input, textarea {
    margin-left: 0;
  }
  .social {
    margin-bottom: 8rem;
  }
  .arrowContainer {
    margin-bottom: 8rem;
  }
}
svg {
  max-width: 100%;
  height: auto;
  display: block;
}

.cont {
  width: 100%;
  height: 200px;
  position: relative;
  margin-bottom: 3rem;
}

.frame {
  height: 50px;
  position: relative;
  top: 30%;
}

.plane-container {
  width: 300px;
  margin: 0px auto;
  z-index: 3;
}

.plane {
  width: 100%;
  height: 30px;
  margin-right: 50px;
  position: relative;
}

.clouds {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  top: -10%;
  transform: translateZ(0);
}

.cloud {
  position: relative;
  top: 20%;
  width: 100%;
  height: 7rem;
  right: 0;
  opacity: 1;
}


.plane-container {
  -webkit-animation: paper-plane-scoping 2s alternate infinite;
  animation: paper-plane-scoping 2s alternate infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes paper-plane-scoping {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(100px);
  }
}

@-webkit-keyframes paper-plane-scoping {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(100px);
  }
}

.plane {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation: paper-plane-soaring 4s forwards infinite;
  animation: paper-plane-soaring 4s forwards infinite;
}

@keyframes paper-plane-soaring {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes paper-plane-soaring {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.clouds {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.cloud {
  position: absolute;
  top: 20%;
  width: 300px;
  right: 0;
  opacity: 1;
}

.cloud.front {
  z-index: 9;
}

.cloud.distant {
  z-index: 1;
}

.cloud.background {
  z-index: 1;
}

.cloud.smaller {
  margin-right: 400px;
  width: 100px;
  margin-top: 50px;
}

.cloud.small {
  margin-right: 200px;
  width: 150px;
}

.cloud.big {
  width: 500px;
  margin-top: 50px;
  margin-right: 150px;
}

.cloud.massive {
  width: 600px;
  margin-top: 20px;
  margin-right: 0px;
}

.cloud {
  -webkit-animation-name: cloud-movement;
  -webkit-animation-timing-function: linear;
  -webkit-animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 8s;
  -moz-animation-name: cloud-movement;
  -moz-animation-timing-function: linear;
  -moz-animation-direction: forwards;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 8s;
  animation-name: cloud-movement;
  animation-timing-function: linear;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-duration: 8s;
}

.slow {
  -webkit-animation-duration: 9.2s;
  animation-duration: 9.2s;
}

.slower {
  -webkit-animation-duration: 11.2s;
  animation-duration: 11.2s;
}

.slowest {
  -webkit-animation-duration: 13.5s;
  animation-duration: 13.5s;
}

.super-slow {
  -webkit-animation-duration: 20.5s;
  animation-duration: 20.5s;
}

@keyframes cloud-movement {
  0% {
    opacity: 0.1;
    transform: translateX(300px);
  }
  10% {
    opacity: 0.7;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(-1000px);
  }
}

@-webkit-keyframes cloud-movement {
  0% {
    opacity: 0.1;
    transform: translateX(300px);
  }
  10% {
    opacity: 0.7;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(-1000px);
  }
}
.About_About__3sgSL {
    display: flex;
    flex-direction: column;
    padding: 2rem 5rem;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 100vh;
    width: 100%;
    margin-top: auto;
}

@media (max-width: 1300px) {
    .About_About__3sgSL {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.About_About__3sgSL h2 {
    margin-top: 0.6rem;
    margin-bottom: 1rem;
    font-family: swp;
    font-size: 2.3rem;
}

.About_About__3sgSL p {
    margin: 0;
    font-family: 'Titillium Web', sans-serif;
    /* font-style: italic; */
}

.About_About__3sgSL hr {
    width: 100%;
    border: 0.05px solid #8d8d8d;
    opacity: 0;
    margin: 1rem 0;
}

.About_ImageContainer__bobca {
    width: 100%;
    /* height: 40vh; */
    flex-flow: wrap;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.About_Card__bPRdK {
    padding: 1rem;
    background: rgb(46, 46, 46);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    width: 100%;
}

.About_Card__bPRdK i:first-of-type {
    color: rgb(8, 253, 216);
    font-size: 1.5rem;
}

.About_ImageContainer__bobca .About_ImgLeft__SH8ry {
    width: 68%;
    /* height: 100%; */
    height: 40vh;
    border-radius: 0.4rem;
    text-align: center;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-origin: content-box !important;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    /* transition: transform 0.25s; */
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.1);
    /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5); */
}

.About_ImgRightContainer__1Eajx {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40vh;
    width: 20%;
}

.About_ImageContainer__bobca .About_ImgRight__31w9I {
    width: 100%;
    height: 48%;
    border-radius: 0.4rem;
    text-align: center;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-origin: content-box !important;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    /* transition: transform 0.25s; */
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.1);
    /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5); */
}

/* .ImgLeft:hover, .ImgRight:hover {
    transform: scale(1.1)
} */

.About_Youtube__3hqSb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 1rem 0;
}

.About_Youtube__3hqSb iframe {
    border: none;
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.1);
}

@media (max-width: 1300px) {
    .About_AboutContainer__3yl19 .About_ImgRightContainer__1Eajx {
        width: 40%;
    }
    .About_AboutContainer__3yl19 .About_ImgRightContainer__1Eajx:last-of-type {
        flex-direction: row;
        width: 100%;
        margin-top: 0.5rem;
        height: 20vh;
    }
    .About_AboutContainer__3yl19 .About_ImgRightContainer__1Eajx:last-of-type .About_ImgRight__31w9I {
        width: 49%;
        height: 100%;
    }
    .About_FactsMain__1pn8n {
        width: 58% !important;
    }
    .About_FactsSecondary__2OpKo {
        width: 40% !important
    }
    .About_FactsContainer__2R33B .About_ImgRightContainer__1Eajx {
        width: 40%;
    }
    .About_FactsContainer__2R33B .About_ImgRightContainer__1Eajx {
        flex-direction: row;
        width: 100%;
        margin-top: 0.5rem;
        height: 20vh;
    }
    .About_FactsContainer__2R33B .About_ImgRightContainer__1Eajx .About_ImgRight__31w9I {
        width: 49%;
        height: 100%;
    }
    .About_About__3sgSL {
        padding-bottom: 8rem;
    }
}

.About_FactsContainer__2R33B {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.About_Facts__1pYnK {
    width: 100%;
    /* height: 10vh; */
    margin: 1rem 0;
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    align-items: center;
}

.About_FactContainer__3LdHV {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1px;
}

.About_FactContainer__3LdHV div:last-of-type {
    font-size: 0.8rem;
    margin-top: -1rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 200;
}

.About_Fact__26yF1 {
    min-height: 8vh;
    max-height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    color: rgb(8, 253, 216);
}

.About_Fact__26yF1 i {
    margin-right: 4px;
}

.About_PriceContainer__PdrvW {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-evenly;
    padding-bottom: 3rem;
}

.About_Priceheading__35t6J h2 {
    margin-bottom: 0 !important;
}

.About_Price__1Ek8L {
    /* height: 100%; */
    /* width: 13vw; */
    /* margin:0 5px */
    text-align: center;
}

.About_Price__1Ek8L ul {
    list-style: none;
}

.About_Price__1Ek8L li {
    margin-top: 10px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 200;
}

.About_Title__2Tpxp {
    font-size: 1.4rem;
    font-weight: 400 !important;
}

.About_PriceHour__3eZ7B span {
    color: rgb(8, 253, 216);
    font-size: 3rem;
}

.About_PriceHour__3eZ7B span::before {
    content: '€';
    font-size: 1.7rem;
    display: inline-block;
    transform: translateY(-13px);
}

.About_PriceHour__3eZ7B span::after {
    content: '/hour';
    font-size: 1rem;
}

.About_Choose__2Z1fN {
    font-family: 'Rock Salt', cursive;
    font-size: 1rem;
    color: rgb(8, 253, 216) !important;
    text-align: center;
    cursor: pointer;
}

.About_Choose__2Z1fN:hover {
    color: rgb(0, 0, 0) !important;
}
.Card_Card__1oeUq {
    padding: 1rem;
    background: rgb(46, 46, 46);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
    /* border-radius: 1rem; */
    width: 100%;
}

.Card_Card__1oeUq i:first-of-type {
    color: rgb(8, 253, 216);
    font-size: 1.5rem;
}

.Card_Card__1oeUq h2 {
    margin-top: 0.6rem;
    margin-bottom: 1rem;
    font-family: swp;
    font-size: 2.3rem;
}
.Education_Education__PI-0S {
    padding: 2rem 5rem;
    width: 100%;
    height: auto;
    margin: auto;
    font-family: 'Titillium Web', sans-serif;
}

@media (max-width: 1400px) {
    .Education_Education__PI-0S {
        padding: 2rem 0;
    }
}

.Education_Education__PI-0S h4 {
    margin: 0;
}

.Education_Education__PI-0S h5 {
    margin: 0;
    margin-top: 0.2rem;
}

.Education_Title__2w5vx {
    text-align: center;
    text-align: left;
    font-size: 0.7rem;
    color: #8d8d8d;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 200;
    margin: 0;
    margin-bottom: 1rem;
}

.Education_Education__PI-0S p {
    margin: 0;
    font-family: 'Titillium Web', sans-serif;
    /* font-style: italic; */
    font-weight: 200;
}

.Education_Wrapper__1iXeZ {
    display: flex;
    justify-content: space-evenly;
    /* margin: 3rem 0 */
}

.Education_Wrapper__1iXeZ span, .Education_Wrapper__1iXeZ h5 {
    color: rgb(8, 253, 216);
}

.Education_Wrapper__1iXeZ span {
    cursor: pointer;
}

.Education_Edu__2uDT6 {
    min-width: 48%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.Education_Exp__z49bU {
    min-width: 48%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.Education_Edu__2uDT6 i:first-of-type, .Education_Exp__z49bU i:first-of-type {
    color: rgb(8, 253, 216);
    font-size: 1.5rem;
}

.Education_Edu__2uDT6 h2, .Education_Exp__z49bU h2 {
    margin-top: 0.6rem;
    margin-bottom: 1rem;
    font-family: swp;
    font-size: 2.3rem;
}

.Education_ListWrapper__2VWCl {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

@media (max-width: 1150px) {
    .Education_ListWrapper__2VWCl {
        flex-direction: column;
    }
}

.Education_RespTech__14YOI {
    margin-top: 1rem;
    width: 48%;
}

.Education_RespTech__14YOI p {
    font-weight: 400;
    font-style: normal;
}

.Education_RespTech__14YOI ul {
    font-size: 1rem;
    /* color: #8d8d8d; */
    font-family: 'Titillium Web', sans-serif;
    font-weight: 200;
}

@media (max-width: 768px) {
    .Education_Wrapper__1iXeZ {
        flex-direction: column;
    }
    .Education_Education__PI-0S{
        padding-bottom: 8rem;
    }
}
.Skills_Skills__Kr93Q {
    padding: 2rem 5rem;
    width: 100%;
    min-height: 100%;
    font-family: 'Titillium Web', sans-serif;
    margin: auto;
    /* margin-bottom: 8rem !important; */
}

.Skills_Skills__Kr93Q p {
    margin-bottom: 3rem;
    /* font-style: italic; */
}

.Skills_Wrapper__m4k8T {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

@media (max-width: 1400px) {
    .Skills_Skills__Kr93Q {
        padding: 2rem 1rem;
    }
}

@media (max-width: 1300px) {
    .Skills_Wrapper__m4k8T {
        flex-direction: column;
    }
    /* .Skills {
        padding: 2rem 1rem;
    } */
    .Skills_Animation__3yY-8 {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
    }
}

.Skills_SkillsWrapper__J19FA {
    min-width: 35%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}

.Skills_Animation__3yY-8 {
    min-width: 55%;
}

.Skills_Technical__fyLTW {
    width: 100%;
    height: 100%;
}

.Skills_Professional__3_CS3 {
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Skills_ProfessionalRow__mlod_ {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
}

@media (max-width: 900px) {
    .Skills_Professional__3_CS3 {
        margin-top: 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .Skills_ProfessionalRow__mlod_ {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .Skills_Wrapper__m4k8T {
        flex-direction: column;
        margin-bottom: 2rem;
    }
    .Skills_Skills__Kr93Q {
        padding-bottom: 8rem;
    }
    .Skills_Animation__3yY-8 {
        margin-top: 1rem;
    }
}
.Bar_Bar__2463p {
    width: 100%;
    height: 100%;
    font-family: 'Titillium Web', sans-serif;
}

.Bar_TitleWrapper__3rKHR {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.Bar_Title__2RchS {
    font-weight: bold;
}

.Bar_Precent__143W2 {
    color: rgb(8, 253, 216);
}

.Bar_Status__3cLvu {
    width: 100%;
    height: 7px;
    background: #8d8d8d;
    border-radius: 7px;
    position: relative;
}

.Bar_Progress__2P3As {
    width: 80%;
    height: 7px;
    background: #08fdd8;
    border-radius: 7px;
    position: relative;
    transform: translateY(-7px);
}
.CircleBar_CircleWrapper__2tEhb {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CircleBar_Circle__15CdK {
    width: 50%;
}

@media (max-width: 768px) {
    .CircleBar_CircleWrapper__2tEhb div {
        font-size: 0.9rem;
    }
}
.Portfolio_Portfolio__1B3Qu {
    padding: 2rem 5rem;
    width: 100%;
    height: auto;
    min-height: 100vh;
    font-family: 'Titillium Web', sans-serif;
    margin: auto;
}

.Portfolio_Coming__2XaeZ{
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.Portfolio_Menu__1xO4c {
    display: flex;
    justify-content: center;
}

.Portfolio_MenuItem__3DjoJ {
    /* margin: 0 2rem; */
    width: 9%;
    /* background-color: blue; */
    cursor: pointer;
    text-align: center;
}

.Portfolio_Active__1HwU0 {
    border-bottom: 2px solid rgb(8, 253, 216);
}

.Portfolio_ImageContainer__1xSPx {
    display: flex;
    justify-content: center;
}

.Portfolio_ImageColumn__26E0M {
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 20%;
}

.Portfolio_Item__2cBxC {
    margin-top: 1rem;
    height: 20vh;
    width: 100%;
    border-radius: 1rem;
    text-align: center;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-origin: content-box !important;
    transition: transform 0.25s;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
}

.Portfolio_Item__2cBxC:hover {
    transform: scale(1.1)
}

@media (max-width: 1300px) {
    .Portfolio_Menu__1xO4c {
        justify-content: space-evenly;
    }
    .Portfolio_MenuItem__3DjoJ {
        width: auto;
    }
    .Portfolio_Portfolio__1B3Qu {
        padding: 2rem 1rem;
    }
}

@media (max-width: 768px) {
    .Portfolio_Portfolio__1B3Qu {
        padding-bottom: 8rem;
    }
    .Portfolio_Menu__1xO4c {
        justify-content: space-evenly;
    }
    .Portfolio_MenuItem__3DjoJ {
        width: auto;
    }
    .Portfolio_ImageColumn__26E0M {
        width: 32%;
    }
}
.Content_Content__1uT_7::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

.Content_Content__1uT_7::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #8b8c8b;
}
.PortfolioViewer_PortFolioViewer__2pS-w {
    z-index: 700;
    position: fixed;
    width: 70%;
    left: 15%;
    height: 70%;
    top: 15%;
    color: white;
}

.PortfolioViewer_Content__3mzeR {
    display: flex;
    width: 100%;
    height: 95%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 5%;
    justify-content: space-between;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 200;
    /* font-style: italic; */
}

.PortfolioViewer_Content__3mzeR::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

.PortfolioViewer_Content__3mzeR::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #8b8c8b;
}

.PortfolioViewer_Description__RjO9T {
    width: 49%;
}

.PortfolioViewer_ImageContainer__14p49 {
    width: 49%;
}

.PortfolioViewer_Title__WpJED {
    font-size: 150%;
    font-weight: bolder;
}

.PortfolioViewer_Stack__nIDHB {
    margin-top: 10%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.PortfolioViewer_Stack__nIDHB div {
    border: 1px solid rgb(8, 253, 216);
    padding: 2% 4%;
    margin: 1%;
    border-radius: 10px;
    /* width: auto; */
    min-width: 30%;
    text-align: center;
}

.PortfolioViewer_ImageContainer__14p49 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 70vh;
}

.PortfolioViewer_Image__cLr_k {
    width: 100%;
    height: 48%;
    border-radius: 1rem;
    text-align: center;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-origin: content-box !important;
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.1);
    /* transition: transform 0.25s; */
}

/* .Image:hover {
    transform: scale(1.1)
} */

@media (max-width: 768px) {
    .PortfolioViewer_PortFolioViewer__2pS-w {
        z-index: 700;
        position: fixed;
        width: 96%;
        left: 2%;
        height: 80%;
        top: 10%;
        color: white;
    }
    .PortfolioViewer_Content__3mzeR {
        padding: 1%;
        font-size: 75%;
    }
}
.Backdrop_Backdrop__3j6VK {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 600;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
}
