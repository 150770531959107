.Portfolio {
    padding: 2rem 5rem;
    width: 100%;
    height: auto;
    min-height: 100vh;
    font-family: 'Titillium Web', sans-serif;
    margin: auto;
}

.Coming{
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.Menu {
    display: flex;
    justify-content: center;
}

.MenuItem {
    /* margin: 0 2rem; */
    width: 9%;
    /* background-color: blue; */
    cursor: pointer;
    text-align: center;
}

.Active {
    border-bottom: 2px solid rgb(8, 253, 216);
}

.ImageContainer {
    display: flex;
    justify-content: center;
}

.ImageColumn {
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 20%;
}

.Item {
    margin-top: 1rem;
    height: 20vh;
    width: 100%;
    border-radius: 1rem;
    text-align: center;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-origin: content-box !important;
    transition: transform 0.25s;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
}

.Item:hover {
    transform: scale(1.1)
}

@media (max-width: 1300px) {
    .Menu {
        justify-content: space-evenly;
    }
    .MenuItem {
        width: auto;
    }
    .Portfolio {
        padding: 2rem 1rem;
    }
}

@media (max-width: 768px) {
    .Portfolio {
        padding-bottom: 8rem;
    }
    .Menu {
        justify-content: space-evenly;
    }
    .MenuItem {
        width: auto;
    }
    .ImageColumn {
        width: 32%;
    }
}