.App {
  text-align: center;
  background: rgb(17, 17, 17);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background-logo {
  position: fixed;
  width: calc(100vw - 30rem);
  height: 100%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .background-logo {
    position: fixed;
    width: 100%;
    height: calc(100vh - 10vh);
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
