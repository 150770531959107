.Skills {
    padding: 2rem 5rem;
    width: 100%;
    min-height: 100%;
    font-family: 'Titillium Web', sans-serif;
    margin: auto;
    /* margin-bottom: 8rem !important; */
}

.Skills p {
    margin-bottom: 3rem;
    /* font-style: italic; */
}

.Wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

@media (max-width: 1400px) {
    .Skills {
        padding: 2rem 1rem;
    }
}

@media (max-width: 1300px) {
    .Wrapper {
        flex-direction: column;
    }
    /* .Skills {
        padding: 2rem 1rem;
    } */
    .Animation {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
    }
}

.SkillsWrapper {
    min-width: 35%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}

.Animation {
    min-width: 55%;
}

.Technical {
    width: 100%;
    height: 100%;
}

.Professional {
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ProfessionalRow {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
}

@media (max-width: 900px) {
    .Professional {
        margin-top: 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .ProfessionalRow {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .Wrapper {
        flex-direction: column;
        margin-bottom: 2rem;
    }
    .Skills {
        padding-bottom: 8rem;
    }
    .Animation {
        margin-top: 1rem;
    }
}