.PortFolioViewer {
    z-index: 700;
    position: fixed;
    width: 70%;
    left: 15%;
    height: 70%;
    top: 15%;
    color: white;
}

.Content {
    display: flex;
    width: 100%;
    height: 95%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 5%;
    justify-content: space-between;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 200;
    /* font-style: italic; */
}

.Content::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

.Content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #8b8c8b;
}

.Description {
    width: 49%;
}

.ImageContainer {
    width: 49%;
}

.Title {
    font-size: 150%;
    font-weight: bolder;
}

.Stack {
    margin-top: 10%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.Stack div {
    border: 1px solid rgb(8, 253, 216);
    padding: 2% 4%;
    margin: 1%;
    border-radius: 10px;
    /* width: auto; */
    min-width: 30%;
    text-align: center;
}

.ImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 70vh;
}

.Image {
    width: 100%;
    height: 48%;
    border-radius: 1rem;
    text-align: center;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-origin: content-box !important;
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.1);
    /* transition: transform 0.25s; */
}

/* .Image:hover {
    transform: scale(1.1)
} */

@media (max-width: 768px) {
    .PortFolioViewer {
        z-index: 700;
        position: fixed;
        width: 96%;
        left: 2%;
        height: 80%;
        top: 10%;
        color: white;
    }
    .Content {
        padding: 1%;
        font-size: 75%;
    }
}