.SideMenu {
    background: #181818;
    min-width: 5rem;
    max-width: 5rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 500;
    overflow: hidden;
}

.Mobile {
    background: #181818;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 500;
    overflow: hidden;
}