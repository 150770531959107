.About {
    display: flex;
    flex-direction: column;
    padding: 2rem 5rem;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 100vh;
    width: 100%;
    margin-top: auto;
}

@media (max-width: 1300px) {
    .About {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.About h2 {
    margin-top: 0.6rem;
    margin-bottom: 1rem;
    font-family: swp;
    font-size: 2.3rem;
}

.About p {
    margin: 0;
    font-family: 'Titillium Web', sans-serif;
    /* font-style: italic; */
}

.About hr {
    width: 100%;
    border: 0.05px solid #8d8d8d;
    opacity: 0;
    margin: 1rem 0;
}

.ImageContainer {
    width: 100%;
    /* height: 40vh; */
    flex-flow: wrap;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.Card {
    padding: 1rem;
    background: rgb(46, 46, 46);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    width: 100%;
}

.Card i:first-of-type {
    color: rgb(8, 253, 216);
    font-size: 1.5rem;
}

.ImageContainer .ImgLeft {
    width: 68%;
    /* height: 100%; */
    height: 40vh;
    border-radius: 0.4rem;
    text-align: center;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-origin: content-box !important;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    /* transition: transform 0.25s; */
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.1);
    /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5); */
}

.ImgRightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40vh;
    width: 20%;
}

.ImageContainer .ImgRight {
    width: 100%;
    height: 48%;
    border-radius: 0.4rem;
    text-align: center;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-origin: content-box !important;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    /* transition: transform 0.25s; */
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.1);
    /* box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5); */
}

/* .ImgLeft:hover, .ImgRight:hover {
    transform: scale(1.1)
} */

.Youtube {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 1rem 0;
}

.Youtube iframe {
    border: none;
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 0.1);
}

@media (max-width: 1300px) {
    .AboutContainer .ImgRightContainer {
        width: 40%;
    }
    .AboutContainer .ImgRightContainer:last-of-type {
        flex-direction: row;
        width: 100%;
        margin-top: 0.5rem;
        height: 20vh;
    }
    .AboutContainer .ImgRightContainer:last-of-type .ImgRight {
        width: 49%;
        height: 100%;
    }
    .FactsMain {
        width: 58% !important;
    }
    .FactsSecondary {
        width: 40% !important
    }
    .FactsContainer .ImgRightContainer {
        width: 40%;
    }
    .FactsContainer .ImgRightContainer {
        flex-direction: row;
        width: 100%;
        margin-top: 0.5rem;
        height: 20vh;
    }
    .FactsContainer .ImgRightContainer .ImgRight {
        width: 49%;
        height: 100%;
    }
    .About {
        padding-bottom: 8rem;
    }
}

.FactsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Facts {
    width: 100%;
    /* height: 10vh; */
    margin: 1rem 0;
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    align-items: center;
}

.FactContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1px;
}

.FactContainer div:last-of-type {
    font-size: 0.8rem;
    margin-top: -1rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 200;
}

.Fact {
    min-height: 8vh;
    max-height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    color: rgb(8, 253, 216);
}

.Fact i {
    margin-right: 4px;
}

.PriceContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-evenly;
    padding-bottom: 3rem;
}

.Priceheading h2 {
    margin-bottom: 0 !important;
}

.Price {
    /* height: 100%; */
    /* width: 13vw; */
    /* margin:0 5px */
    text-align: center;
}

.Price ul {
    list-style: none;
}

.Price li {
    margin-top: 10px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 200;
}

.Title {
    font-size: 1.4rem;
    font-weight: 400 !important;
}

.PriceHour span {
    color: rgb(8, 253, 216);
    font-size: 3rem;
}

.PriceHour span::before {
    content: '€';
    font-size: 1.7rem;
    display: inline-block;
    transform: translateY(-13px);
}

.PriceHour span::after {
    content: '/hour';
    font-size: 1rem;
}

.Choose {
    font-family: 'Rock Salt', cursive;
    font-size: 1rem;
    color: rgb(8, 253, 216) !important;
    text-align: center;
    cursor: pointer;
}

.Choose:hover {
    color: rgb(0, 0, 0) !important;
}