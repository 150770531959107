.Content::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

.Content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #8b8c8b;
}